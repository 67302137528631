import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const { isSmallDevice, isIPad } = useWindowDimensions();

  useEffect(() => {
    // Consider both small devices and iPads as mobile
    setIsMobile(isSmallDevice || isIPad);
    
    // Log for debugging
    if (typeof window !== 'undefined') {
      console.log('useMobile hook:', {
        isSmallDevice,
        isIPad,
        isMobile: isSmallDevice || isIPad,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString()
      });
    }
  }, [isSmallDevice, isIPad]);

  return isMobile;
}
