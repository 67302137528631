import Link from 'next/link';
import AppLayout from '../components/AppLayout';

export default function FourOhFour() {
  const styles = {
    height: "80vh", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
  }

  return <>
    <AppLayout>
      <div style={styles}>
        <h1 >404 - Page Not Found</h1>
        <Link href="/">
          <a>
            Go back home
          </a>
        </Link>
      </div>
    </AppLayout>
  </>
}