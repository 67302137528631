import styled, { css } from "styled-components";
import { Button, Typography } from "antd";

const { Text } = Typography;

const resetStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 10.5px 6px 10.5px;
`;

export const CustomButton = styled(Button)`
  /* style based on the button type */
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $ptype }) => $ptype === "reset" && resetStyle}
  ${({ theme, $ptype }) => theme.components.button[$ptype].text}
  background-color: ${({ theme, $ptype, $bgcolor }) =>
    $bgcolor ? $bgcolor : theme.components.button[$ptype].bgColor};
  color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
  border-radius: ${({ $bradius }) => ($bradius ? $bradius : "24px")};
  border: none;
  cursor: pointer;
  outline: none;
  ${({ theme, $ptype, $bgcolor, $shadow }) =>
    $shadow === "yes" &&
    `box-shadow: 0px 2px 6px ${$bgcolor ? $bgcolor : theme.components.button[$ptype].shadowColor
    }`};
  ${({ $ptype, theme }) =>
    $ptype === "default" && `border: solid 1px ${theme.colors.blackGreen}`};

  /* general style */
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $width }) => $width && `width: ${$width};`};
  ${({ $ptop }) => $ptop && `padding-top: ${$ptop};`};
  ${({ $pbottom }) => $pbottom && `padding-bottom: ${$pbottom};`}
  ${({ $pleft }) => $pleft && `padding-left: ${$pleft};`}
  ${({ $pright }) => $pright && `padding-right: ${$pright};`}
  ${({ $mtop }) => $mtop && `margin-top: ${$mtop};`}
  ${({ $mbottom }) => $mbottom && `margin-bottom: ${$mbottom}`};
  ${({ $mleft }) => $mleft && `margin-left: ${$mleft};`}
  ${({ $mright }) => $mright && `margin-right: ${$mright}`};

  &.ant-btn:hover {
    background-color: ${({ theme, $ptype, $hoverbgcolor }) =>
    $hoverbgcolor
      ? $hoverbgcolor
      : theme.components.button[$ptype].hoverBgColor}!important;
    border-color: ${({ theme }) => theme.colors.black};
    span {
      color: ${({ theme, $ptype }) => theme.components.button[$ptype].hoverColor};
    }
  }
  &.ant-btn:focus {
    background-color: ${({ theme, $ptype, $bgcolor }) =>
    $bgcolor ? $bgcolor : theme.components.button[$ptype].bgColor};
    border-color: ${({ theme }) => theme.colors.black};
    span {
      color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
    }
  }
  &.ant-btn:active {
    background-color: ${({ theme, $ptype, $bgcolor }) =>
    $bgcolor ? $bgcolor : theme.components.button[$ptype].bgColor};
  }
  &.ant-btn[disabled] {
    background-color: ${({ theme, $ptype, $disabledbgcolor }) =>
    $disabledbgcolor
      ? $disabledbgcolor
      : theme.components.button[$ptype].disabledBgColor};
    opacity: 0.4;
  }
  &.ant-btn[disabled]:hover {
    background-color: ${({ theme, $ptype, $disabledbgcolor }) =>
    $disabledbgcolor
      ? $disabledbgcolor
      : theme.components.button[$ptype].disabledBgColor};
    span {
      color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
    }
  }
  &.ant-btn[disabled]:focus {
    background-color: ${({ theme, $ptype, $disabledbgcolor }) =>
    $disabledbgcolor
      ? $disabledbgcolor
      : theme.components.button[$ptype].disabledBgColor};
    span {
      color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
    }
  }
  &.ant-btn[disabled]:active {
    background-color: ${({ theme, $ptype, $disabledbgcolor }) =>
    $disabledbgcolor
      ? $disabledbgcolor
      : theme.components.button[$ptype].disabledBgColor};
    span {
      color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
    }
  }
`;

export const ButtonText = styled(Text)`
  /* style based on the button type */
  ${({ theme, $ptype }) => theme.components.button[$ptype].text}
  color: ${({ theme, $ptype, $color }) =>
    $color ? $color : theme.components.button[$ptype].color};
  font-weight: bold;
  letter-spacing: 1px;
`;
